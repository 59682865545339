.unread-badge {
  background-color: red;
  color: white;

  padding: 5px 10px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  min-width: 20px;
  text-align: center;
}
