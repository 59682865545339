.pagefoter{
  width: 100%;
  margin: 0;
  padding-top: 150px;
}
.footer {
  position: relative; /* لازم برای موقعیت‌دهی مطلق عناصر درون فوتر */
  height: 300px; /* ارتفاع فوتر */
  background-color: #333; /* رنگ پس‌زمینه فوتر */
  text-align: center;
  color: white;
  
}
.footer p{
  margin: 0;
  text-align: center; /* مرکز کردن متن */
  position: relative;
  top: 75%;
  font-family: "VAZIR";
}

.icons {

  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 200px;
  background-color: rgba(250, 235, 215, 0.207);
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  color: white;
  font-size: 24px;
  z-index: 1;
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7),
              0 0 30px rgba(255, 255, 255, 0.5),
              0 0 45px rgba(255, 255, 255, 0.3);
}

.icons svg {
  cursor: pointer;
  font-size: 2rem;
  color: white;
  transition: color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.icons svg:hover {
  color: #333;
  transform: scale(1.2); /* بزرگ‌تر شدن آیکون */
}
