.color-guide{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 40px;
}
.color-guide p{
  margin: 0px;
  display: flex;
}
.color-guide div{
  margin: 5px;
  margin-right: 30px;
  
}
.auctiondate{
  width:220px;
  margin: 20px;


}
.auctiondatecart{
  border-radius: 5px 15px;
  background-color: rgba(255, 255, 255, 0.3); 
}
.auctions-container-date {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 20px;
  padding: 20px;
  justify-content: center; /* فاصله مساوی بین آیتم‌ها */
}
