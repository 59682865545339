/* storeinfo.css */
.storemanegment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0 auto;
  max-width: 600px;
  border-radius: 5px 25px 5px 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.storemanegment h2 {
  color: #333;
  margin-bottom: 20px;
}

/* storeinfo.css */
.storemanegment .btn {
  padding: 10px 20px;
  margin: 10px 5px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* storeinfo.css */
.storemanegment .btn-primary {
  background-color: #007bff;
  color: #fff;
  border-radius: 5px 25px 5px 25px;
}

/* storeinfo.css */
.storemanegment .btn-primary:hover {
  background-color: #0056b3;
}

/* storeinfo.css */
.storemanegment .btn-success {
  background-color: #28a745;
  color: #fff;
  border-radius: 5px 25px 5px 25px;
}

/* storeinfo.css */
.storemanegment .btn-success:hover {
  background-color: #218838;
}

/* storeinfo.css */
.storemanegment .btn-warning {
  background-color: #ffc107;
  color: #fff;
  border-radius: 5px 25px 5px 25px;
}

/* storeinfo.css */
.storemanegment .btn-warning:hover {
  background-color: #e0a800;
}

/* storeinfo.css */
.storemanegment .btn-secondary {
  background-color: #6c757d;
  color: #fff;
  border-radius: 5px 25px 5px 25px;
}

/* storeinfo.css */
.storemanegment .btn-secondary:hover {
  background-color: #5a6268;
}

/* storeinfo.css */
.storemanegment .create-store-form, .store-info {
  width: 100%;
  background-color: rgba(250, 235, 215, 0.405);
  padding: 20px;
  border-radius: 5px 25px 5px 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* storeinfo.css */
.storemanegment .create-store-form input, .store-info input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* storeinfo.css */
.storemanegment .store-info img {
  margin-top: 20px;
  background-color: white;
  border-radius: 5px 25px 5px 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* storeinfo.css */
.storemanegment .store-info h3 {
  margin-top: 20px;
  color: #333;
}

/* storeinfo.css */
.storemanegment .store-info p, .create-store-form p {
  text-align: center;
  color: #333;
}

/* storeinfo.css */
.storemanegment .store-info p a {
  color: #007bff;
  text-decoration: none;
}

/* storeinfo.css */
.storemanegment .store-info p a:hover {
  text-decoration: underline;
}

/* storeinfo.css */
.storemanegment .btn-danger {
  background-color: #dc3545;
  color: #fff;

}

/* storeinfo.css */
.storemanegment .btn-danger:hover {
  background-color: #c82333;
}
/* storeinfo.css */
.storemanegment .editingStoreName{
  display: flex;
  width: 100%;
  align-items: stretch;
  flex-direction: column;
  float: left;
}
/* storeinfo.css */
.storemanegment .editingStoreName p {
  text-align: justify;
  width: auto;
  font-family: "VAZIR";
}
.storemanegment .editingStoreName span{
  font-family: "VAZIRT";
}
/* storeinfo.css */
.storemanegment .editingImage{
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
}
/* storeinfo.css */
.storemanegment .editingImage h3{
  float: left;
  width: 100%;
}
/* storeinfo.css */
.storemanegment .editingImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

/* storeinfo.css */
.storemanegment .editingImage h3 {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

/* storeinfo.css */
.storemanegment .image-edit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* storeinfo.css */
.storemanegment .image-edit-container input[type="file"] {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* storeinfo.css */
.storemanegment .image-edit-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

/* storeinfo.css */
.storemanegment .image-edit-actions button {
  flex-grow: 1;
  padding: 10px 0;
  border-radius: 5px 25px 5px 25px;
}
