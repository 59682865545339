.menuitem{
  display: flex;
  flex-direction: column;
  align-items: center;

}
.menuProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(250, 235, 215, 0.207);
  width: 90%;
  border-radius: 10px;
  margin: 5%;
  overflow-x: scroll;
}

.menuProfile button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin: 10px 0;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 40px; /* عرض اولیه برابر با عرض آیکون */
  transition: width 0.3s ease, background-color 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
  margin: 10px;
}
.menuProfile button svg{
  position: relative;
  left: 10px;
}
.menuProfile button span{
  position: relative;
  left: -5px;
}

.menuProfile button .menuText {
  visibility: hidden;
  opacity: 0;
  transform: translateX(-10px);
  transition: visibility 0.3s, opacity 0.3s, transform 0.3s;
}

.menuProfile button:hover {
  width: 150px; /* عرضی که بعد از هاور شدن به دکمه اختصاص می‌دهید */
}

.menuProfile button.active {
  width: auto; /* عرض خودکار براساس محتوای داخل دکمه */
  padding-right: 15px; /* برای تنظیم فاصله متن از سمت راست دکمه */
}

.menuProfile button:hover .menuText,
.menuProfile button.active .menuText {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.btn-danger {
  background-color: #d9534f;
  color: white;
}

.btn-danger:hover,
.btn-danger.active {
  background-color: #c9302c;
}

.detailsProfile{
  background-color: rgba(250, 235, 215, 0.207);
  border-radius: 5px 25px 5px 25px;
  width: 90%;
  padding: 20px;
  margin: 20px;
}
/* profile.css */

.store-messages {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9;
}

.store-messages h3 {
  margin-bottom: 10px;
  color: #333;
}

.store-messages ul {
  list-style-type: none;
  padding: 0;
}

.store-messages ul li {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.store-messages ul li:last-child {
  border-bottom: none;
}

.message-time {
  font-size: 0.85em;
  color: #666;
  display: block;
  margin-top: 5px;
}
@media (max-width: 530px) {
  .menuText{
    display: none;
    
  }
  .menuProfile button.active{
    background-color: #333;
    color: white;
    text-align: center;
    padding-right: 20px;
  }
  .detailsProfile {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}
.editingStoreName div {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  
}
}