html, body {
  direction: rtl;
  overflow-x: hidden;        /* جلوگیری از اسکرول افقی */
}

.header {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* IE10+/Edge */
  user-select: none;         /* Standard */
  position: relative;
  height: auto; /* تغییر به 100vh برای اطمینان از پر شدن صفحه */
  overflow-y: hidden; /* مخفی کردن اسکرول عمودی در هدر */
  font-family: "VAZIR";
  transition: background 1s ease; /* انتقال انیمیشن برای تغییر رنگ */
  
}
.part1 {
  display: flex;
  width: 100%;
  height: 10vh;
  align-items: center;
  position: relative; 

}


.part1 .part1part {
  flex: 1;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.part1 .middle {
  z-index: 2;
  flex: 2;
  background-color: rgba(250, 235, 215, 0.207);
  padding: 10px;
  border-radius: 10px;
  position: sticky; /* تغییر به sticky */
  top: 0; /* موقعیت ثابت در بالای صفحه */
}

.part1 .menu ul {
  list-style: none;
  display: flex;
  gap: 1rem; /* فاصله بین آیتم‌های منو */
  margin: 0;
  padding: 0;
}

.part1 .menu ul li {
  margin: 0 15px;
  position: relative; /* ضروری برای استفاده از pseudo-element ها */
}
.part1 .menu ul li .icon {
  display: none;
}
.part1 .menu ul li .text {
  display: inline;
}
.part1 .menu ul li .userinterface_icon {
  display: none;
}
.part1 .userinterface_text {
  display: inline;
}

.part1 .menu ul li a {
  color: black;
  display: grid;
  place-content: center;
  margin: 0;
  text-decoration: none; /* حذف زیرخط لینک‌ها */
  font-weight: bold;
  font-size: 15px; /* سایز فونت پیش‌فرض */
}


.part1 .menu ul li .menuoptions::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: black; /* رنگ خط */
  border-radius: 5px;
  transform: scaleX(0); /* خط به صورت اولیه نامرئی */
  transition: transform 0.5s ease; /* انتقال برای انیمیشن */
  bottom: -5px; /* تنظیم محل خط زیر لینک */
  left: 0;
}

.part1 .menu ul li .menuoptions:hover::before {
  transform: scaleX(1); /* خط به هنگام هاور ظاهر می‌شود */
}


.part2 {
  position: relative;
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.part2 .text {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100px;
  font-family: "VAZIRB";
  font-size: 10vw;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.text {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.text p {
  display: inline-block;
  width: 100%;
  animation: marquee 15s linear infinite;
}

.part2 .img {
  width: 50%;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
}

.part3 {
  display: flex;
  width: 100%;
  font-family: "VAZIRT";
  text-align: center;
  align-items: stretch;
}

.part3 .part3part {
  flex: 1;
  margin: 5px;
  border-radius: 10px;
  padding: 10px;
}

.part3 .part3part.middle {
  flex-grow: 2;
  background-color: rgba(250, 235, 215, 0.207);
  padding: 10px;
}

.part3 .part3part p {
  margin: 10px 0;
}

.part3 .part3part .btn {
  margin-top: auto;
}
.part3 .btnNext:hover{
  background-color: rgba(17, 17, 17, 0.776);
  transform: rotate(360deg);
  transition: all 1s ease;
}

.part3 .btnNext {
  width: 60px;
  height: 60px;
  margin: 10px;
  position: relative;
  top: 25%;
  bottom: 25%;
  border-radius: 50%;
  background-color: rgba(250, 235, 215, 0.207);
  border: none;
}
