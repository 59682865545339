.titelAuctions{
  width: 90%;
  margin: 5%;
  text-align: center;
  font-family: "VAZIRB";
  background-color: rgba(250, 235, 215, 0.207);
  border-radius: 5px;
  padding: 10px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7),
              0 0 30px rgba(255, 255, 255, 0.5),
              0 0 45px rgba(255, 255, 255, 0.3);
  

}