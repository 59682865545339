.loginpage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "VAZIR";
  overflow: hidden; /* مخفی کردن بخش‌های خارج از کادر */
  max-height: 100vh; /* حداکثر ارتفاع صفحه */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginAndRegister {
  position: relative;
  width: 90vw; /* درصدی برای انعطاف‌پذیری بهتر */
  max-width: 800px;
  height: 80vh; /* ارتفاع درصدی برای اسکرول مناسب */
  background-color: rgba(250, 235, 215, 0.405);
  backdrop-filter: blur(5px);
  border-radius: 5px 25px 5px 25px;
  transition: transform 0.5s ease-in-out;
  overflow: auto; /* فعال کردن اسکرول */
}

.login, .register {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  text-align: center;
}

.login {
  left: 0;
  z-index: 2;
  transition: transform 0.5s ease-in-out;
}

.register {
  left: 100%;
  z-index: 1;
  transition: transform 0.5s ease-in-out;
}

.login input, .register input {
  display: block;
  margin: 10px auto;
  width: 70%;
  height: 40px;
  border-radius: 5px 20px;
  border: none;
  outline: none;
  text-align: center;
}

.show-register .login {
  transform: translateX(-100%);
}

.show-register .register {
  transform: translateX(-100%);
}

.loginpage .login button, .register button {
  width: 150px;
  margin: 10px;
  
}

.password-container {
  position: relative;
  width: 70%;
  margin: 10px auto;
  
}

.password-container input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px; /* ایجاد فاصله برای آیکون چشم */
  border-radius: 5px 20px;
}

.eye-icon {
  position: absolute;
  top: 50%;
  left: 10px; /* آیکون را به سمت چپ منتقل می‌کنیم */
  transform: translateY(-50%);
  cursor: pointer;
  color: #333;
  font-size: 1.2rem;
}

.btn_left {
  border-radius: 5px 20px 20px 5px;
}

.btn_right {
  border-radius: 20px 5px 5px 20px;
}
