.message-container {
  display: flex;
  flex-direction: row-reverse;
  height: 100vh;
}
.massegandbidsBox{
  display: block;
  flex-direction: row-reverse;
  background-color: rgba(250, 235, 215, 0.207);
  width: 90%;
  padding: 30px;
  border-radius: 8px;
  backdrop-filter: blur(10px); /* اعمال فیلتر تاری */
  -webkit-backdrop-filter: blur(10px); 
  margin: 10px;
}
.message-box {
  border-radius: 5px 15px;
  background-color: #333;
  max-height: calc(60vh - 0px); /* Full viewport height minus input height */
  overflow-y: auto;
  width: 500px;
  min-height: 500px;
}

.message-list {
  flex: 1;
  padding: 10px;
  margin-bottom: 70px;
}

.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 8px;
  max-width: 100%;
  word-wrap: break-word;
}

.message.user {
  background-color: #e0e0e0;
  align-self: flex-end;
  margin-left: auto;
  padding: 10px;
  position: relative;
  color: black;
  width: 70%;
  left: -145px;
  border-radius: 2px 8px 8px 8px;
}

.message.modir {
  background-color: #097423;
  color: white;
  align-self: flex-start;
  border-radius:2px 2px  8px 8px;
  width: 100%;
}
.message.modir h5{
  text-align: center;
}
.message.admin {
  background-color: #007bff;
  color: white;
  align-self: flex-start;
  border-radius:8px 2px  8px 8px;
  width: 70%;


}

.message.user p{
  text-align: left;
}
.message p {
  margin: 0;
}

.message p {
  margin: 0;
}

.comment-form {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ddd;
  padding: 10px;
  position: fixed;
  bottom: 30px;
  width: 500px;
  z-index: 10;
  justify-content: space-between;
  backdrop-filter: blur(10px); /* اعمال فیلتر تاری */
  -webkit-backdrop-filter: blur(10px); 
  border-radius: 0px 0px 5px 15px;
  
}



textarea.form-control {
  resize: none;
  width: 85%;
  height: 50px;
}

.message-container button.btn-primary {
  width: 10%;
  height: 50px;

}
.menubtnflex{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.containerA .menumaneg{
  display: flex;
  background-color: rgba(250, 235, 215, 0.207);
  width: 90%;
  padding: 30px;
  border-radius: 8px;
  backdrop-filter: blur(10px); /* اعمال فیلتر تاری */
  -webkit-backdrop-filter: blur(10px); 
  
}
.containerA .menumaneg button{
  background-color: #333;
  color: white;
  border: none;
  margin: 5px;
  
}
.containerA .menumaneg .btndanger{
  background-color: red;
  border-radius: 5px;
}
.dataAuction{
  display: flex;
  justify-content: space-around;
  background-color: rgba(250, 235, 215, 0.207);
  width: 90%;
  padding: 30px;
  border-radius: 8px;
  backdrop-filter: blur(10px); /* اعمال فیلتر تاری */
  -webkit-backdrop-filter: blur(10px); 
  margin: 10px;
}
.dataAuction span{
  font-family: "VAZIRB";
  font-size: 18px;
}
.massegandbidsBox .bids-list{
  width: 80%;
}
.massegandbidsBox .bids-list p{
  text-align: center;
  font-family: "VAZIRB";
  color: white;
}
.massegandbidsBox .top .bid-form{
  border-radius: 8px;
  border-radius:  15px 15px 5px 5px;
  background-color: #333;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 10px;
  
}
.massegandbidsBox .top .form-group{
  display: flex;
}
.massegandbidsBox .top  p{
  text-align: center;
  color: white;
  font-family: "VAZIRB";
}
.massegandbidsBox .top button{
  width: auto;
  height: 50px;
  margin: 10px;
}
.massegandbidsBox .top input{
  height: 50px;
  margin: 10px;
  outline: none;
}
.massegandbidsBox .bottom{
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
}
.massegandbidsBox .list-group{

  display: flex;
  width: 90%;
  border-radius: 8px;

  border-radius: 15px  5px;
  background-color: #333;
  max-height: calc(75vh - 0px); /* Full viewport height minus input height */
  overflow-y: auto;
  width: 500px;
  min-height: 500px;
  padding: 10px;
  
}
.list-group-item{
  background-color: rgba(250, 235, 215, 0.207);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.winner {
  background-color: #f9f79f; /* رنگ طلایی ملایم */
  font-weight: bold;
  font-family: "VAZIRB";
  color: black;
}


@media (max-width: 1000px) {
  .massegandbidsBox {
    flex-direction: column;
    background-color: rgba(250, 235, 215, 0.207);
    /* width: 90%; */
    padding: 30px;
    border-radius: 8px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    margin: 10px;
    flex-wrap: nowrap;
    align-items: center;
}


.massegandbidsBox .bids-list {
  margin-bottom: 20px;
  width: 100%;
}
.container, .container-sm {
  max-width: auto;
}
.massegandbidsBox .list-group {
  display: flex;
  width: 300px;
  border-radius: 8px;
  border-radius: 15px 5px;
  background-color: #333;
  max-height: calc(75vh - 0px);
  overflow-y: auto;
  min-height: 500px;
  padding: 10px;
}
.message-box {
  width: 300px;
}
.message-box {
  border-radius: 5px 15px;
  background-color: #333;
  max-height: calc(60vh - 0px);
  overflow-y: auto;
  width: 300px;
  min-height: 500px;
  align-items: center;
  flex-direction: column;
}
.comment-form {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ddd;
  padding: 10px;
  position: fixed;
  bottom: 50px;
  width: 300px;
  z-index: 10;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 0px 0px 5px 15px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.massegandbidsBox .top .bid-form {
  border-radius: 8px;
  border-radius: 15px 15px 5px 5px;
  background-color: #333;
  overflow-y: auto;
  margin-bottom: 10px;
}
.dataAuction {
  display: flex;
  justify-content: space-around;
  background-color: rgba(250, 235, 215, 0.207);
  width: 90%;
  padding: 30px;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  margin: 10px;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;
}
.message.user {

  left: -85px;

}
}

@media (max-width: 740px) {
  .massegandbidsBox {
    display: flex;
    flex-direction: column;
    background-color: rgba(250, 235, 215, 0.207);
    /* width: 90%; */
    padding: 30px;
    border-radius: 8px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    margin: 10px;
    flex-wrap: nowrap;
    align-items: center;
}
.massegandbidsBox .bottom {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column-reverse;
}
.comment-form {
  bottom: 30px;
}
.massegandbidsBox .top .bid-form {
  border-radius: 8px;
  border-radius: 15px 15px 5px 5px;
  background-color: #333;
  overflow-y: auto;
  margin-bottom: 10px;
  width: 300px;
}
.massegandbidsBox .top button {
  height: 50px;
  margin: 10px;
  width: 54px;
  overflow: hidden;
}
.massegandbidsBox .list-group {
  display: flex;
  width: 300px;
  border-radius: 8px;
  border-radius: 15px 5px;
  background-color: #333;
  max-height: calc(75vh - 0px);
  overflow-y: auto;
  min-height: 300px;
  padding: 10px;
}
}