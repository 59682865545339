@media screen and (max-width:900px) {
  .part1 .menu ul li a {
    font-size: 2vh;
  }
  .part1{
    display: inline-block;
  }
  .part1 .middle {
    position: fixed;
    /* position: relative; */
    width: 94%;
    margin: 3%;
    margin-top: 30px;
    z-index: 33;
    backdrop-filter: blur(10px); /* اعمال فیلتر تاری */
    -webkit-backdrop-filter: blur(10px); /* برای پشتیبانی از مرورگرهای قدیمی‌تر */
    
  }
  .part1 .menu ul li .icon {
    display: inline;
  }
  .part1 .menu ul li .icon svg {
    display: inline;
   font-size: 20px;
  }
  .part1 .menu ul li {
    margin: 0 5px;
    padding: 0;
    position: relative; /* ضروری برای استفاده از pseudo-element ها */
  }
  .part1 .menu ul li .text {
    display: none;
  }
  .part1 .menu ul li .userinterface_icon {
    display: inline;
  }
  .part1 .userinterface_text {
    display: none;
  }
  .part2{
    margin-top: 50px;
    height: 280px;
  }
  .part3 {
    display: block;
    width: 100%;
    font-family: "VAZIRT";
    text-align: center;
    align-items: stretch;
  }
}
@media screen and (min-width:864px) and (max-width:1045px){

}
@media screen and (min-width:704px) and (max-width:863px){

}

@media screen and (min-width:300px) and (max-width:703px){

}