 /* تنظیمات برای صفحه اصلی فروشگاه */
.storPage {
  background-color: rgba(250, 235, 215, 0.207);
  backdrop-filter: blur(30px);
  border-radius: 5px 25px 5px 25px;
  height: auto;
  overflow-y: auto; /* امکان اسکرول */
  padding-bottom: 20px;
}

/* تنظیمات برای هدر فروشگاه */
.headerStor {
  display: flex;
  flex-direction: row-reverse;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  backdrop-filter: blur(10px); 
  -webkit-backdrop-filter: blur(10px); 
}

.headerStor img {
  border-radius: 5px 25px 5px 25px;
  margin: 20px;
}

.storename {
  margin: 20px;
  font-family: "VAZIRB";
}

.storename span {
  font-family: "VAZIRT";
}

/* تنظیمات برای بخش مزایده‌ها */
.auctinsStors {
  border-radius: 5px 25px 5px 25px !important;
}

.card-footer {
  padding: 20px;
  margin: 20px;
  padding-left: 40px;
  padding-right: 40px;
  font-family: "VAZIRB";
  border-radius: 10px;
  background-color: rgba(250, 235, 215, 0.207);
  height: 60%;
  overflow-x: auto; /* تنظیم overflow برای اسکرول افقی */
}

/* استایل برای محیط چت */
.massegwithstor {
  width: 40%;
  max-width: 600px; /* حداکثر عرض */
  height: 80%;
  min-height: 500px;
  background: #333;
  border-radius: 5px 15px;
  padding: 20px;
  font-family: 'VAZIRT', sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  overflow: auto; /* تنظیم overflow برای اسکرول */
}
.massegwithstor p{
  text-align: left;
  color: white;
}
/* استایل برای پس‌زمینه بلور شده */
.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* استایل برای پیام‌ها */
.masseges {
  width: 100%;
  height: calc(100% - 100px); /* تنظیم ارتفاع داینامیک */
  background-color: rgba(250, 235, 215, 0.207);
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  overflow-y: auto; /* تنظیم overflow برای اسکرول */
}

/* استایل برای هر پیام */
.message-item {
  padding: 5px;
  color: white;
  border-radius: 10px;
  margin-bottom: 5px;
  max-width: 80%;
}

.message-item-right {
  text-align: right;
  background-color: #007bff;
  margin-left: auto;
  margin: 10px;
  border-radius: 10px 0  10px 10px;
}

.message-item-left {
  margin: 10px;
  text-align: left;
  background-color: #6c757d;
  margin-right: auto;

  border-radius: 0 10px  10px 10px;

}

.message-timestamp {
  font-size: 0.8em;
  color: #f8f9fa;
}

/* استایل برای بخش ارسال پیام */
.sender {
  width: 100%;
  height: 50px;
  background-color: rgba(250, 235, 215, 0.207);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.sender input {
  margin-left: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 8px;
  width: 90%;
}

.sender button {
  flex-shrink: 0;
  padding: 10px;
}

/* دکمه بستن چت */
.btn-close-chat {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 5px;
}

/* تنظیمات واکنش‌گرا */
@media (max-height: 768px) {
  .massegwithstor {
    width: 90%;
    height: 90%; /* افزایش ارتفاع در صفحات کوچک‌تر */
    min-height: unset; /* حذف حداقل ارتفاع */
  }

  .storPage {
    padding: 10px; /* کاهش padding برای صفحات کوچک‌تر */
  }
}

/* تنظیمات کلی برای اطمینان از قابلیت اسکرول کل صفحه */
body, html {
  height: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
  
}
.message-item {
  position: relative;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f1f1f1;
  max-width: 60%;
  color: black;
}

.message-item-right {
  margin-left: auto;
  background-color: #d1f7d1;
}

.message-item-left {
  margin-right: auto;
  background-color: #f1f1f1;
}

.message-timestamp {
  font-size: 0.8rem;
  color: #888;
  margin-top: 5px;
}

.message-status {
  font-size: 0.8rem;
  color: #888;
  margin-top: 5px;
  text-align: right; /* تنظیم جهت راست به چپ برای پیام‌های خود کاربر */
}

.message-status-icon {
  margin-right: 5px;
}
@media (max-width: 830px) {
  .headerStor {

  flex-direction: column;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  align-items: center;
  flex-wrap: nowrap;

  }
  .card-footer {
    padding-left: 0;
    padding-right: 0;
  }
}