.captcha-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.captcha-display {
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  background-color: #333;
  padding: 10px;
  border-radius:20px 20px 5px 5px;
  margin-top: 15px;
  width: 200px;
}

.refresh-btn {
  background: none;
  border: none;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
  color: #007bff;
  background: black;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: .5s;
}

.refresh-btn:hover {
  transform: rotate(360deg);
}

.captcha-input {
  padding: 8px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  width: 100%;
  max-width: 200px;
}

.captcha-error {
  color: rgb(0, 0, 0);
  font-size: 14px;
  margin-top: 5px;
}
.captcha-error span{
  color: rgba(255, 0, 0, 0.649);
}
