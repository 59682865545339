.auction {
  width: 250px;
  height: 300px;
  direction: ltr;
  font-family: "VAZIRT";
  position: relative; /* اضافه کردن موقعیت نسبی به .auction */
  margin-bottom: 20px;
}

.auction .img {
  position: absolute; /* موقعیت‌دهی مطلق به تصویر */
  width: 100px;
  height: 100px;
  margin: 10px;
  background-color: rgb(162, 162, 162);
  z-index: 2;
  border-radius: 5px 15px 5px 15px;
  /* تنظیم موقعیت تصویر */
  right: 0; /* موقعیت سمت راست */
  top: 18%; /* موقعیت عمودی: نیمه ارتفاع عنصر والد */
  transform: translateY(-50%); /* جابجایی عمودی برای مرکز کردن */
}

.caption {
  direction: rtl;
  position: relative; /* موقعیت‌دهی نسبی به .caption */
  width: 200px;
  height: 300px;
  background-color:#212529;
  border-radius: 5px 15px 5px 15px;
  padding-right: 10px; /* افزودن کمی فاصله داخلی */
  color: white;
  transition: all .5s ease;
}
.caption:hover {
  box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.454); /* سایه نورانی سفید */
}
.caption .titel {
  padding-right: 70px;
  padding-top: 10px;
  height: 120px;
}

.caption .datacaption {
  padding-right: 10px;
  height: 135px;
  width: 100%;
}

.caption button {
  position: absolute; /* موقعیت‌دهی مطلق به دکمه */
  bottom: 0px; /* فاصله از پایین */
  left: 0px; /* فاصله از سمت چپ */
  padding: 10px 20px; /* پدینگ داخلی برای زیبایی بیشتر */
  border: none; /* حذف حاشیه */
  font-size: 16px; /* اندازه فونت */
  cursor: pointer; /* تغییر شکل نشانگر موس */
  border-radius: 0px 15px 0px 15px;
}


.auctions-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* برای اینکه کارت‌ها در صفحه‌های کوچک به پایین بروند */
  gap: 20px; /* فاصله بین کارت‌ها */
  padding: 20px; /* padding برای فاصله از لبه‌ها */
}

/* سبک برای دیالوگ مودال */
/* سبک برای دیالوگ مودال */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.5s ease, backdrop-filter 0.5s ease; /* اضافه کردن انتقال برای افکت‌ها */
  font-family: "VAZIR";

}

/* افکت بلور کردن پس‌زمینه */
.modal-active {
  text-align: justify;
  opacity: 1; /* شفافیت ۱ زمانی که مودال فعال است */
  backdrop-filter: blur(5px); /* بلور کردن پس‌زمینه */

}
.modal-active img{
  height: 300px;
  border-radius: 5px 25px 5px 25px;
  border: none;
}
.modal-active .data{
  text-align: justify;
  font-family: "VAZIRT";
}
.modal-active .details{
  width: 30%;
  text-align: justify;
  font-family: "VAZIRT";
}

.modal-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: black;
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 5px 25px 5px 25px;
  width: 100%;
  max-width: 1000px;
  height: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  transform: translateY(-50px); /* شروع از بالای صفحه */
  transition: transform 0.5s ease; /* انتقال برای باز شدن مودال */
  margin: 10px;
  overflow-x: scroll;
}

.modal-content.modal-active-content {
  transform: translateY(0); /* موقعیت نهایی مودال */
}

/* بقیه استایل‌ها بدون تغییر باقی می‌مانند */


.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 25px;
  cursor: pointer;
  background-color: red;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 0px 23px 0px 10px;
  transition: 1s;
  display: flex; /* برای مرکز کردن محتوا در داخل دکمه */
  justify-content: center; /* مرکز چین کردن افقی محتوا */
  align-items: center; /* مرکز چین کردن عمودی محتوا */
}

.close-button span {
  display: inline-block; /* برای اعمال transform */
  transition: transform 0.5s ease-in-out; /* مدت زمان انیمیشن چرخش */
}

.close-button:hover span {
  transform: rotate(360deg); /* چرخش 360 درجه */
}
.auction .img {
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 10px;
  background-color: rgb(162, 162, 162);
  z-index: 2;
  border-radius: 5px 15px 5px 15px;
  right: 0;
  top: 18%;
  transform: translateY(-50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* سایه اولیه */

  /* افکت سایه متحرک */
  animation: shadowPass 2s infinite ease-in-out;
}

@keyframes shadowPass {
  0% {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  50% {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.caption button {
  position: absolute;
  bottom: -15px; /* فاصله دکمه از پایین کپشن */
  left: -10px; /* فاصله دکمه از چپ کپشن */
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px 15px 5px 15px;
  overflow: hidden; /* برای پنهان کردن بخش‌های اضافی افکت */
  z-index: 1; /* برای اطمینان از اینکه افکت روی دکمه نمایش داده می‌شود */
  background-color: white; /* رنگ پس‌زمینه دکمه */
  color: #333; /* رنگ متن دکمه */
}

.modal-images{
  display: flex;
  width: 300px;
  height: 300px;
  overflow-x: scroll;
  border: none;
}

