.storslist ul{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;

  border-radius: 5px 15px;
  margin: 40px;
}
.storslistSearchBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  
}
.storslistSearchBox input{
  padding: 10px;
  width: 40%;
  border-radius: 5px;
  background-color: rgba(250, 235, 215, 0.207);
  border: none;
  outline: none;
  text-align: center;

}
.storslistSearchBox input:focus{
  background-color: rgba(250, 235, 215, 0.207);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7),
  0 0 30px rgba(255, 255, 255, 0.5),
  0 0 45px rgba(255, 255, 255, 0.3);
}

.storslist li{
  display: flex;
  text-align: center;
  margin: 20px;
}
.storslist li .img{
  width: 50px;
  height: 50px;
  transition: all .5s ease;
}
.storslist li .img:hover{
  width: 60px;
  height: 60px;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7),
              0 0 30px rgba(255, 255, 255, 0.5),
              0 0 45px rgba(255, 255, 255, 0.3);
}
.storslist .NavLink{
  text-decoration: none;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
