@media screen and (max-width:900px) {
  .icons {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 100px;
    background-color: rgba(250, 235, 215, 0.207);
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    color: white;
    font-size: 24px;
    z-index: 1;
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7),
                0 0 30px rgba(255, 255, 255, 0.5),
                0 0 45px rgba(255, 255, 255, 0.3);
  }
}