/* App.css */

body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: "VAZIRT";
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* جلوگیری از اسکرول افقی */
}

body {
  min-height: 100vh; /* اطمینان از اینکه ارتفاع بدن همیشه برابر با ارتفاع صفحه نمایش است */
  background-size: cover; /* پس‌زمینه به طور کامل پوشش داده شود */
  background-position: center; /* مرکز قرار دادن پس‌زمینه */
  background-attachment: fixed; /* پس‌زمینه در هنگام اسکرول ثابت بماند */
  background-repeat: no-repeat; /* جلوگیری از تکرار پس‌زمینه */
}


.extra1, .extra2, .extra3, .extra4, .extra5 {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3); /* شفافیت بیشتر برای تاثیر بهتر */
  animation: float 10s ease-in-out infinite;
  z-index: -22;

  opacity: 0.7;
}

.BackgroundSwitcher{
  display: none;
}

body.day-mode {
  animation: backgroundChangeNightToDay 1s forwards;
  background: radial-gradient(circle at center, #ff7e5f, #feb47b);
  background-color: #fca767;
  color: black;
}

/* رنگ زمینه شب */
body.night-mode {
  animation: backgroundChangeDayToNight 1s forwards;
  background: radial-gradient(circle at center, #9900ff, #610380);
  background-color: #7108b7;
  /* color: white; */
}

.extra1 {
  top: 10%;
  left: 15%;
  width: 20px;
  height: 20px;
  animation-duration: 8s;
}

.extra2 {
  top: 30%;
  left: 60%;
  width: 25px;
  height: 25px;
  animation-duration: 10s;
}

.extra3 {
  top: 50%;
  left: 25%;
  width: 15px;
  height: 15px;
  animation-duration: 7s;
}

.extra4 {
  top: 70%;
  left: 75%;
  width: 30px;
  height: 30px;
  animation-duration: 12s;
}

.extra5 {
  top: 80%;
  left: 45%;
  width: 10px;
  height: 10px;
  animation-duration: 6s;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px); /* جابجایی بیشتر */
  }
  100% {
    transform: translateY(0);
  }
}

@font-face {
  font-family: "VAZIR";
  src: url("./style/font/vazir-font-v29.1.0/Vazir-Medium.ttf");
}
@font-face {
  font-family: "VAZIRB";
  src: url("./style/font/vazir-font-v29.1.0/Vazir-Bold.ttf");
}

@font-face {
  font-family: "VAZIRT";
  src: url("./style/font/vazir-font-v29.1.0/Vazir-Thin.ttf");
}