.edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  backdrop-filter: blur(5px); /* بلور کردن پس‌زمینه */
  transition: opacity 0.3s ease;

}

.edit-modal.show {
  opacity: 1;
}

.edit-modal-content {
  background-color: rgba(250, 235, 215, 0.405);
  border-radius: 5px 25px 5px 25px;
  max-width: 600px;
  width: 100%;
  height: 80%;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: auto;
}

.edit-modal-content h2 {
  margin-top: 0;
  font-size: 24px;
  color: #333;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}

.edit-modal .closebutton {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.edit-modal .closebutton span {
  display: inline-block;
  transition: transform 0.3s ease;
}

.edit-modal .closebutton:hover span {
  transform: rotate(90deg);
}

.edit-modal .edit-modal-content input,
.edit-modal .edit-modal-content textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  border-radius: 5px 20px;
  border: none;
  outline: none;
}

.edit-modal .edit-modal-content textarea {
  resize: vertical;
  min-height: 100px;
}

.edit-modal .edit-modal-content input[type="file"] {
  padding: 10;
  padding-right: 25%;
  border: 1px solid white;
}

.edit-modal .images-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-end;
}

.edit-modal .image-preview {
  flex: 1;
  display: flex;
  max-width: calc(50% - 10px);
  margin-bottom: 10px;
  flex-direction: column;
}

.edit-modal .image-preview img {
  width: 100%;
  border-radius: 5px 15px 0px 0px;
}

.edit-modal .image-preview p {
  font-size: 14px;
  margin: 5px 0;
}
.edit-modal .edit-modal-content .save-button {
  background-color: #28a745;
  border-radius: 0px 15px 5px 0px;
}

.edit-modal .edit-modal-content .cancel-button {
  background-color: #dc3545;
  border-radius:  5px 0px 0px 15px;
}

.edit-modal .edit-modal-content button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius:  0px 0px 5px 15px;
}

.edit-modal-content button:hover {
  background-color: #333;
  color: #fff;
}


.inputsEditModal{
  padding: 30px;
}
.edit-modal .close-button {
  position: sticky;
}