@media screen and (max-width:900px) {
  .modal-content {
    display: block;
    overflow-x: scroll;
    text-align: center;
    margin: 45px;
    padding: 0;
  }
  .modal-active .data{
    width: 100%;
    padding:  45px;
  }
  .modal-active .details{
    width: 100%;
    padding:  45px;
  }
  .close-button {
    position: sticky;
  }
}
